import { EnvironmentConfiguration } from './environment-types';

const appUri: string = 'https://w1vmgmt.awapps.com';

export const environment: EnvironmentConfiguration = {
  production: true,
  baseUri: appUri,
  deploymentEnvironment: 'prod',
  version: '2025-02-27+4541_prod',
  apiBaseUrl: 'https://w1v.awapps.com/core',
  okta: {
    clientId: '0oa31bursg7gzazAs4x7',
    issuer: 'https://login.amwater.net/oauth2/aus37lnvffvC0PPH74x7',
    redirectUri: `${appUri}/login/callback`,
    scopes: ['openid', 'profile', 'email','W1V'],
    pkce: true,
  },
  w1vApi:{
    apikey:"c24QrKzIKzA5DH42lloT8XB6KTvWTMyC",
    baseUrl:"https://w1v.awapps.com/core",
    originalUrl:'https://w1v.awapps.com',
    sapUrl:"https://awprd.prod.apimanagement.us10.hana.ondemand.com:443/",
    c1vUrl: "https://c1v.awapps.com/openidlogin",
    meterOpsUrl: "https://meterops.amwater.com/",
  },
  userRoles:{
    FRCC:"W1V_frcc",
    AW_SUPERVISOR:"W1V-Supervisors",
   CONT_SUPERVISOR:"W1V_CONTSUP",
   ADMIN:"W1V_POWERUSER"
  }
};
